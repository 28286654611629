import * as React from "react"
import { Link } from "gatsby"

import SEO from "../components/seo";
import LayoutSub from "../components/layoutSub";

import Home from "../static/svg/icon-home.inline.svg";

function NotFoundPage({ location, pageContext }) {

  const { breadcrumb: { crumbs } } = pageContext;

  const { pathname } = location;
  const pagetitle = "Not found";
  const description = "お探しのページが見つかりません";

  return (
    <LayoutSub crumbs={crumbs} pathname={pathname} isNotFound  >
      <SEO
        pageurl={pathname}
        pagetitle={pagetitle}
        description={description}
      />
      <section>

        <title>お探しのページが見つかりません / Not found</title>
        <h1 className=" my-2 heading-b">お探しのページが見つかりません / Page not found</h1>
        <p>
          申し訳ありません / Sorry{" "}

          <span role="img" aria-label="Pensive emoji">
            😔
          </span>{" "}
        </p>
        <p>
          お探しのページが見つかりません / We couldn&apos;t find what you were looking for.

        </p>
        <button type='button'
          className="border border-gray-500 hover-hover:hover:bg-gray-200  text-center py-2 px-4 my-8 rounded font-semibold shadow">
          <Link to='/' >
            <Home className="h-6 w-6 inline-block  align-text-bottom" />
            トップページへ戻る
          </Link>
        </button>
        &nbsp;&nbsp;
        <button type='button'
          className="border border-gray-500 hover-hover:hover:bg-gray-200  text-center py-2 px-4 my-8 rounded font-semibold shadow">
          <Link to='/en/' >
            <Home className="h-6 w-6 inline-block  align-text-bottom" />
            Back to Home
          </Link>
        </button>
      </section>
    </LayoutSub>
  )
}

export default NotFoundPage
